import VideoConnection from "./VideoConnection";

export interface Zoom {
  min: number;
  max: number;
  value: number;
  step: number;
}

export interface CameraState {
  cameraIndex: number;
  rotation?: number;
  zoom?: Zoom;
}
type StateHandler = (state: CameraState) => void;

export default class CameraConnection extends VideoConnection {
  protected channel?: RTCDataChannel;
  private stateHandler: StateHandler | null = null;
  private ready: boolean = false;
  private messageQueue: any[] = [];

  constructor(private shouldCreateDataChannel = false) {
    super();
    this.createDataChannel();
    this.connection.addEventListener("datachannel", this.onDataChannel);
  }

  public createDataChannel() {
    if (this.shouldCreateDataChannel) {
      console.log("Creating data channel");
      this.initDataChannel(this.connection.createDataChannel("state"));
    }
  }

  protected initDataChannel(channel: any) {
    this.channel = channel;
    this.channel!.addEventListener("open", this.onChannelStateChange);
    this.channel!.addEventListener("close", this.onChannelStateChange);
    this.channel!.addEventListener("message", this.onMessage);
  }

  protected onDataChannel = (event: any) => {
    console.log("Receive Channel Callback");
    this.initDataChannel(event.channel);
  };

  protected onChannelStateChange = () => {
    const readyState = this.channel?.readyState;
    console.log("State channel state is: " + readyState);
    if (readyState === "open") {
      this.ready = true;
      this.messageQueue.forEach((message) => {
        this.channel?.send(message);
      });
      this.messageQueue = [];
    }
  };

  public updateState = (state: CameraState) => {
    if (!this.ready) {
      this.messageQueue.push(JSON.stringify(state));
      return;
    }
    this.channel?.send(JSON.stringify(state));
  };

  public onStateUpdate(stateHandler: StateHandler) {
    this.stateHandler = stateHandler;
  }

  protected onMessage = (event: any) => {
    this.stateHandler?.(JSON.parse(event.data));
  };

  public async stopCall() {
    this.channel?.close();
    await super.stopCall();
  }
}
