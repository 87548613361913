import { RotatedVideo, Video } from "./Video";
import { Gallery } from "./Gallery";
import { Camera } from "../interfaces";

interface LocalCamerasProps {
  cameras: Camera[];
  onRotate?: (cameraId: string, index: number, angle: number) => void;
}

export default function LocalCameraGallery({
  cameras,
  onRotate,
}: LocalCamerasProps) {
  return (
    <Gallery
      style={{
        flex: 1,
        minHeight: 0,
        margin: 20,
      }}
      numberOfElements={cameras.length}
    >
      {cameras.map((camera, index) => {
        if (!onRotate) {
          return <Video key={camera.id} stream={camera.stream} />;
        }
        return (
          <RotatedVideo
            key={camera.id}
            stream={camera.stream}
            onRotate={(angle) => onRotate(camera.id, index, angle)}
            rotation={camera.rotation}
          />
        );
      })}

      {cameras.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </Gallery>
  );
}
