import React, { ReactElement } from "react";
import { useMeasure } from "@uidotdev/usehooks";

import { squaresInRectangle } from "./util";
import { GalleryContext, useItemSize } from "./GalleryContext";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

interface GalleryProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  numberOfElements: number;
  emptyComponent?: ReactElement
  onAdd?: () => void
}

const Add = ({ onAdd }: any) => {
  const size = useItemSize()
  return (
    <Button
      style={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        borderColor: "#484848",
        borderWidth: 2,
        borderStyle: 'dashed'
      }}
      size="medium"
      onClick={onAdd}
    >
      <AddIcon style={{ fontSize: size * 0.5, color: '#484848' }} />
    </Button>
  )
}


export const Gallery = ({ numberOfElements, children, emptyComponent, onAdd, ...props }: GalleryProps) => {
  const [ref, { width, height }] = useMeasure();

  if (!numberOfElements) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          ...props.style,
        }}
      >
        {emptyComponent}
      </div>
    )
  }

  if (onAdd) {
    numberOfElements += 1
  }

  const squareWidth =
    width && height && squaresInRectangle(width, height, numberOfElements);

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        ...props.style,
      }}
    >
      <GalleryContext.Provider value={{ size: squareWidth ?? 0 }}>
        {children}
        {onAdd && <Add onAdd={onAdd} />}
      </GalleryContext.Provider>
    </div>
  );
};
