import { Gallery } from "../../../components/Gallery";
import { RemoteCamera } from "../../../components/RemoteCamera";
import { useRemoteCameras } from "./useRemoteCameras";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useSocket,
  useSocketEvent,
} from "../../../providers/WebSocketProvider";
import { useCallback, useEffect } from "react";
import SelectLocalCamera from "./SelectLocalCamera";
import RegisterRemoteCamera from "./RegisterRemoteCamera";
import { Camera } from "../../../interfaces";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { RotatedVideo } from "../../../components/Video";
import { useLocalCameraManager } from "./useLocalCameraManager";

interface CameraOptionProps {
  title: string;
  description: string;
  onClick: () => void;
}

const CameraOption = (props: CameraOptionProps) => (
  <Card>
    <CardContent>
      <Typography gutterBottom sx={{ color: "text.secondary", fontSize: 14 }}>
        {props.title}
      </Typography>
      <Typography variant="body2">{props.description}</Typography>
    </CardContent>
    <CardActions>
      <Button onClick={props.onClick} size="large">
        Auswählen
      </Button>
    </CardActions>
  </Card>
);

const Empty = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        margin: 50
      }}
    >
      <p>Hier kannst du Kameras hinzufügen, um die geworfenen Punkte auf der Dartscheibe automatisch zu erkennen</p>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate(`?status=add`, { replace: false })}
        size="large"
      >
        Kameras auswählen
      </Button>
    </div>
  )
}

export default function AllCameras() {
  const { connect } = useSocket();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedCameras: localCameras,
    selectedCameraIds,
    selectCameras,
    init,
    onRotate,
  } = useLocalCameraManager();
  const { cameras: remoteCameras, initialized } = useRemoteCameras();
  const params = new URLSearchParams(location.search);
  const status =
    (params.get("status") as "gallery" | "add" | "local" | "remote") ||
    "gallery";
  const readonly = params.get("readonly") === "true"

  const numberOfElements =
    remoteCameras.reduce((acc, cam) => acc + cam.cameras.length, 0) +
    localCameras.length;

  useEffect(() => {
    const socket = connect({
      reconnectionAttempts: 5,
      withCredentials: true,
    });
    init(socket);
  }, []);

  useEffect(() => {
    //if (initialized && !numberOfElements) {
    //  navigate(`?status=add`, { replace: true });
    //}
  }, [initialized, numberOfElements]);

  const handleCameraConnected = useCallback((camera: Camera) => {
    navigate(`?status=gallery`, { replace: true });
  }, []);

  useSocketEvent("camera connected", handleCameraConnected);

  if (status === "add") {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Stack spacing={2} direction="column" alignItems="stretch">
          <CameraOption
            title="Lokale Camera"
            description="Nutze die Kamera dieses Geräts"
            onClick={() => navigate(`?status=local`, { replace: false })}
          />
          <CameraOption
            title="Externe Kamera"
            description="Registriere die Kamera eines anderen Geräts"
            onClick={() => navigate(`?status=remote`, { replace: false })}
          />
        </Stack>
      </div>
    );
  }

  if (status === "local") {
    return (
      <SelectLocalCamera
        selectedCameraIds={selectedCameraIds}
        onSelectCameras={async (cameraIds) => {
          selectCameras(cameraIds);
          navigate(`?status=gallery`, { replace: true });
        }}
      />
    );
  }

  if (status === "remote") {
    return <RegisterRemoteCamera />;
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0
      }}
    >
      <Gallery
        style={{
          flex: 1,
          minHeight: 0,
          margin: !readonly ? 10 : 0
        }}
        numberOfElements={numberOfElements}
        emptyComponent={!readonly ? <Empty /> : undefined}
        onAdd={!readonly ? () => navigate(`?status=add`, { replace: false }) : undefined}
      >
        {localCameras.map((cam, i) => (
          <RotatedVideo
            key={i}
            stream={cam.stream}
            onRotate={(angle) => onRotate(cam.id, cam.index, angle)}
          />
        ))}
        {remoteCameras.map((cam, i) => (
          <RemoteCamera key={i} cameras={cam.cameras} />
        ))}
      </Gallery>
      {!readonly && (
        <div style={{ padding: 15 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => (window as any).ReactNativeWebView.postMessage("Continue")}
            size="large"
          >
            Weiter
          </Button>
        </div>
      )}
    </div>
  );
}
