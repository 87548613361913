import axios from "axios";
import { CameraApi, UserApi } from "./generated/api";

export const BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  withCredentials: true,
});

export const cameraApi = new CameraApi(undefined, BASE_URL, instance);
export const userApi = new UserApi(undefined, BASE_URL, instance);
