import { useRef, useState } from "react";
import { CameraManager } from "../../../cameras/CameraManager";
import { Camera } from "../../../interfaces";
import { useCameraService } from "../../../hooks/useCameraService";
import { Options } from "../../../cameras/CameraService";
import { CameraRegistration } from "../../../cameras/CameraRegistration";
import { useQueryParameter } from "../../../hooks/useQueryParameter";

export type Status =
  | "disconnected"
  | "connecting"
  | "registering"
  | "connected"
  | "scanning";

const options: Options = {
  persistenceEnabled: true,
};

export const useLocalCameraRegistration = () => {
  const [status, setStatus] = useState<Status>("disconnected");
  const [user, setUser] = useState<{ name: string } | null>(null);
  const manager = useRef<CameraManager>();
  const registration = useRef<CameraRegistration>();
  const cameraService = useCameraService(options);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [selectedCameraIds, setSelectedCameraIds] = useState<string[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<Camera[]>([]);
  const code = useQueryParameter("code");

  const init = async () => {
    manager.current = new CameraManager(cameraService);
    manager.current.onCameraUpdate(() => {
      setSelectedCameras(cameraService.selectedCameras);
    });
    registration.current = new CameraRegistration(
      cameraService,
      manager.current
    );
    const hasToken = await registration.current!.init();
    setCameras(cameraService.cameras);
    setSelectedCameraIds(cameraService.selectedCameraIds);
    await cameraService.startCameras();
    setSelectedCameras(cameraService.selectedCameras);
    if (code) {
      await register(code);
    } else if (hasToken) {
      await connect();
    } else {
      await startScanning();
    }
  };

  const register = async (joinToken: string) => {
    setStatus("registering");
    try {
      await registration.current!.register(joinToken);
      await connect();
    } catch (err) {
      console.error("Invalid QR Code");
      await startScanning();
    }
  };

  const connect = async () => {
    setStatus("connecting");
    const connection = await registration.current!.connect();
    setStatus("connected");
    setUser(connection.user);
  };

  const deregister = async () => {
    console.log("deregistering");
    await registration.current!.deregister();
    startScanning();
  };

  const selectCameras = async (cameraIds: string[]) => {
    setSelectedCameraIds(cameraIds);
    await manager.current!.selectCameras(cameraIds);
    setSelectedCameras(cameraService.selectedCameras);
    await cameraService.startCameras();
    setSelectedCameras(cameraService.selectedCameras);
  };

  const startScanning = async () => {
    setStatus("scanning");
    console.log("Scanning");
    const url = await registration.current!.startScanning();
    console.log("Scanned", url);
    if (!url) return;
    const joinToken = url.split("code=")[1];
    console.log("Token", joinToken);
    cameraService.stopCameras();
    await cameraService.startCameras();
    setSelectedCameras(cameraService.selectedCameras);
    await register(joinToken);
  };

  const onRotate = (id: string, index: number, angle: number) => {
    manager.current?.rotate(id, index, angle);
  };

  return {
    cameras,
    selectedCameras,
    selectCameras,
    selectedCameraIds,
    init,
    deregister,
    status,
    user,
    onRotate,
  };
};
