import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RegisterCamera from "./routes/RegisterCamera";
import Playground from "./routes/Playground";
import Finder from "./routes/Finder";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import Login from "./routes/Login";
import AllCameras from "./routes/Cameras/AllCameras";
import LocalCameras from "./routes/Cameras/LocalCameras";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/playground",
    element: <Playground />,
  },
  {
    path: "/register",
    element: <RegisterCamera />,
  },
  {
    path: "/finder",
    element: <Finder />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/cameras/local",
    element: <LocalCameras />,
  },
  {
    path: "/cameras/all",
    element: <AllCameras />,
  },
]);

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      50: '#dff9ff',
      100: '#bfe5f0',
      200: '#9bd3e1',
      300: '#77bfd4',
      400: '#53adc6',
      500: '#3994ac',
      600: '#287387',
      700: '#195262',
      800: '#05323c',
      900: '#001219',
    }
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <WebSocketProvider>
          <RouterProvider router={router} />
        </WebSocketProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
