import { Camera } from "../interfaces";
import CameraConnection, { CameraState } from "./CameraConnection";
import Signaling from "./Signaling";
import { CallOptions } from "./VideoConnection";

type ConnectionHandler = (connection: CameraConnection | null) => void;
type StateHandler = (peerId: string, state: CameraState) => void;

export default class ConnectionManager {
  private connectionMap = new Map<string | undefined, CameraConnection>();
  private connectionHandler: ConnectionHandler | null = null;
  private stateHandler: StateHandler | null = null;
  private localStreams: MediaStream[] = [];

  constructor(private signaling: Signaling) {
    this.initSignaling();
  }

  public async startCall(to: string, options: CallOptions = {}) {
    let connection = this.connectionMap.get(to);
    if (connection) {
      await connection.stopCall();
    }
    connection = new CameraConnection(true);
    connection.setVideoStreams(this.localStreams);
    this.handleNewConnection(to, connection);
    const offer = await connection.startCall(options);
    await this.signaling.sendOffer(offer!, to);
    connection.id = to;
    connection.onIceCandidate((candidate) => {
      this.signaling.sendCandidate(candidate, to);
    });

    return connection;
  }

  public async stopCall(peerId: string) {
    console.log("stop call");
    const connection = this.connectionMap.get(peerId);
    if (connection) {
      this.signaling.stop(peerId);
      this._stopCall(peerId);
    }
  }

  public addVideoStream(stream: MediaStream) {
    this.localStreams.push(stream);
  }

  public async setVideoStreams(streams: MediaStream[]) {
    this.localStreams = streams;
  }

  public get connections() {
    return [...this.connectionMap.values()];
  }

  private async _stopCall(peerId?: string) {
    const connection = this.connectionMap.get(peerId);
    if (connection) {
      this.connectionMap.delete(peerId);
      this.connectionHandler?.(null);
      await connection.stopCall();
    }
  }

  protected handleNewConnection(peerId: string, connection: CameraConnection) {
    this.connectionMap.set(peerId, connection);
    connection.onStateUpdate((state) => {
      this.stateHandler?.(peerId, state);
    });
    this.connectionHandler?.(connection);
  }

  public waitForOffers() {
    console.log("Waiting for offers");
    this.signaling.onOffer(this.handleOffer.bind(this));
  }

  public async handleOffer(offer: any, from: string) {
    const connection = new CameraConnection();
    connection.setVideoStreams(this.localStreams);
    connection.onIceCandidate((candidate) => {
      this.signaling.sendCandidate(candidate, from);
    });
    connection.id = from;
    this.handleNewConnection(from, connection);
    const answer = await connection.handleOffer(offer);
    this.signaling.sendAnswer(answer!, from);
    return connection;
  }

  private initSignaling() {
    // Listen for candidate events
    this.signaling.onCandidate((candidate, from) => {
      const connection = this.connectionMap.get(from);
      if (connection) {
        connection.addIceCandiate(candidate);
      }
    });

    this.signaling.onAnswer(async (answer, from) => {
      const connection = this.connectionMap.get(from);
      if (connection) {
        await connection.handleAnswer(answer);
      }
      /*setTimeout(() => {
        connection?.updateState({ cameraIndex: 0, rotation: 90 })
      }, 200)*/
    });

    // Listen for stop events
    this.signaling.onStop((from) => {
      this._stopCall(from);
    });
  }

  public onConnection(connectionHandler: ConnectionHandler) {
    this.connectionHandler = connectionHandler;
  }

  public onStateUpdate(stateHandler: StateHandler) {
    this.stateHandler = stateHandler;
  }
}
