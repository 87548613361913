import React, { useEffect } from "react";
import CameraSelect from "../../../components/CameraSelect";
import LocalCameraGallery from "../../../components/LocalCameraGallery";
import {
  useLocalCameraRegistration,
  Status as StatusType,
} from "./useLocalCameraRegistration";
import { useQueryParameter } from "../../../hooks/useQueryParameter";

interface StatusProps {
  status: StatusType;
  user: { name: string } | null;
  onDisconnect: () => void;
}

const Status = (props: StatusProps) => {
  let text = "Initialisierung...";
  let right: JSX.Element | undefined = undefined;

  if (props.status === "connected") {
    text = `Gekoppelt mit ${props.user?.name}`;
    right = (
      <button
        style={{
          fontSize: 20,
          marginLeft: 20,
          alignSelf: "center",
          display: "flex",
        }}
        onClick={props.onDisconnect}
      >
        Trennen
      </button>
    );
  } else if (props.status === "scanning") {
    text = "QR-Code scannen";
  } else if (props.status === "connecting") {
    text = "Verbindung wird aufgebaut...";
  } else if (props.status === "disconnected") {
    text = "Verbindung wird aufgebaut...";
  }
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        minHeight: 0,
      }}
    >
      <p style={{ fontSize: 20, color: "#FFFFFF" }}>{text}</p>
      {right}
    </div>
  );
};

export default function LocalCameras() {
  const {
    cameras,
    selectedCameras,
    selectedCameraIds,
    selectCameras,
    init,
    status,
    user,
    deregister,
    onRotate,
  } = useLocalCameraRegistration();

  useEffect(() => {
    init();
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <CameraSelect
        cameras={cameras}
        selectedCameras={selectedCameraIds}
        onChange={selectCameras}
      />
      <LocalCameraGallery cameras={selectedCameras} onRotate={onRotate} />
      <Status status={status} user={user} onDisconnect={() => deregister()} />
    </div>
  );
}
