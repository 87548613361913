import React, { useCallback, useEffect, useState } from "react";

import CameraSelect from "../../../components/CameraSelect";
import LocalCameraGallery from "../../../components/LocalCameraGallery";
import { useCameraService } from "../../../hooks/useCameraService";
import { Camera } from "../../../interfaces";
import { Options } from "../../../cameras/CameraService";
import { Button } from "@mui/material";

const options: Options = {
  persistenceEnabled: false,
};

interface SelectLocalCamerasProps {
  selectedCameraIds: string[];
  onSelectCameras: (cameraId: string[]) => void;
}

export default function SelectLocalCameras(props: SelectLocalCamerasProps) {
  const cameraService = useCameraService(options);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<Camera[]>([]);
  const [selectedCameraIds, setSelectedCameraIds] = useState<string[]>(
    props.selectedCameraIds
  );
  useEffect(() => {
    cameraService.init().then(() => {
      cameraService.startCameras().then(() => {
        setSelectedCameraIds(cameraService.selectedCameraIds);
        setCameras(cameraService.cameras);
        setSelectedCameras(cameraService.selectedCameras);
      });
    });
  }, []);

  const selectCameras = useCallback((cameraIds: string[]) => {
    cameraService.selectCameras(cameraIds).then(() => {
      setSelectedCameras(cameraService.selectedCameras);
      setSelectedCameraIds(cameraIds);
    });
  }, []);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <CameraSelect
        cameras={cameras}
        selectedCameras={selectedCameraIds}
        onChange={selectCameras}
      />

      <LocalCameraGallery cameras={selectedCameras} />

      <div
        style={{
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Button
          style={{ marginBottom: 20 }}
          variant="contained"
          onClick={() => props.onSelectCameras(selectedCameraIds!)}
          size="large"
        >
          Auswählen
        </Button>
      </div>
    </div>
  );
}
