import { useEffect, useRef } from "react";

import { useItemSize } from "./Gallery/GalleryContext";
import { RotationContainer } from "./RotationContainer";

interface Props {
  stream?: MediaStream;
  width?: number;
}

export const Video = ({ stream, width }: Props) => {
  const ref = useRef<HTMLVideoElement>();
  let size = useItemSize();
  if (width) size = width;
  useEffect(() => {
    if (ref.current && stream) {
      //@ts-ignore
      ref.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream)
    return (
      <div
        style={{
          width: size,
          height: size,
        }}
      />
    );
  return (
    <div style={{ width: size, height: size, overflow: "hidden" }}>
      <video
        playsInline
        ref={ref as any}
        autoPlay
        width={size}
        height={size}
        muted
        style={{
          objectFit: "cover",
        }}
      ></video>
    </div>
  );
};

interface RotatedVideoProps {
  stream?: MediaStream;
  rotation?: number;
  onRotate?: (angle: number) => void;
}

export const RotatedVideo = ({
  stream,
  rotation,
  onRotate,
}: RotatedVideoProps) => (
  <RotationContainer
    rotation={rotation}
    onRotate={(angle: number) => onRotate?.(angle)}
  >
    <Video stream={stream}></Video>
  </RotationContainer>
);
