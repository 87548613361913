import { ConnectionManager, Signaling } from "../connection";
import { Socket } from "socket.io-client";
import storage from "../storage";
import { CameraService } from "./CameraService";

export type Status = "disconnected" | "connecting" | "connected" | "scanning";

export class CameraManager {
  private manager?: ConnectionManager;
  private socket?: Socket;
  private cameraUpdateListener?: () => void;

  constructor(private cameraService: CameraService) {}

  init = async () => {
    await this.cameraService.init();
  };

  onCameraUpdate = (listener: () => void) => {
    this.cameraUpdateListener = listener;
  };

  connect = async (socket: Socket) => {
    this.socket = socket;
    this.manager = new ConnectionManager(new Signaling(socket));
    for (const camera of this.cameraService.selectedCameras) {
      this.manager.addVideoStream(camera.stream!);
    }
    this.manager.onStateUpdate((peerId, state) => {
      this.cameraService.updateCameraState(state);
      this.cameraUpdateListener?.();
    });
    this.manager.onConnection((connection) => {
      for (let i = 0; i < this.cameraService.selectedCameras.length; i++) {
        connection?.updateState({
          cameraIndex: i,
          rotation: this.cameraService.selectedCameras[i].rotation,
        });
      }
    });
    this.manager.waitForOffers();
  };

  async selectCameras(cameraIds: string[]) {
    const streams = await this.cameraService.selectCameras(cameraIds);
    await this.manager?.setVideoStreams(streams);
    this.socket?.emit("set cameras", {
      count: this.cameraService.selectedCameras.length,
    });
  }

  disconnect = async () => {
    console.log("disconnecting");
    if (this.manager) {
      for (const connection of this.manager.connections) {
        await this.manager.stopCall(connection.id!);
      }
      this.manager = undefined;
      console.log("calls stopped");
    }
  };

  rotate = (id: string, index: number, angle: number) => {
    storage.setRotation(id, angle);
    this.cameraService.updateCameraState({
      cameraIndex: index,
      rotation: angle,
    });
    this.manager?.connections.forEach((connection) => {
      connection.updateState({
        cameraIndex: index,
        rotation: angle,
      });
    });
  };
}
