import { CameraState } from "../connection";
import { Camera, DeepPartial } from "../interfaces";
import storage from "../storage";

export interface Options {
  persistenceEnabled: boolean;
  defaultCameraIds?: string[];
}

export class CameraService {
  public cameras: Camera[] = [];
  public selectedCameraIds: string[] = [];

  constructor(private options: Options) { }

  private async getVideoDevices() {
    const devices =
      (await navigator.mediaDevices.enumerateDevices()) as MediaDeviceInfo[];
    const cameras = devices.filter((device) => device.kind === "videoinput");
    return cameras;
  }

  private async getDevices() {
    let cameras = await this.getVideoDevices();

    // If this camera permission is not given yet
    // all labels will be empty
    if (!cameras.find((cam) => cam.label)) {
      // Ask for permission
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
      });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
      cameras = await this.getVideoDevices();
    }

    return cameras;
  }

  private getDefaultCameraIds(cameras: Camera[]) {
    if (this.options.persistenceEnabled) {
      const cameraIndexes: number[] = storage.getCameraIndexes();
      if (cameraIndexes) {
        return cameraIndexes.map((index) => cameras[index]?.id).filter(Boolean);
      }
    }
    if (this.options.defaultCameraIds) {
      return this.options.defaultCameraIds;
    }
    if (cameras.length === 2) {
      const back = cameras.find((camera) => camera.label?.includes("back"));
      const front = cameras.find((camera) => camera.label?.includes("front"));

      if (back && front) {
        return [back.id];
      }
    }
    return [cameras[0].id];
  }

  public async init() {
    const devices = await this.getDevices();
    console.log("FILTERED DEVICES", devices);
    this.cameras = devices.map((camera, index) => {
      return {
        id: camera.deviceId,
        label: camera.label || `Camera ${index + 1}`,
        index,
        isRemote: false,
        rotation: storage.getRotation(camera.deviceId),
      };
    });
    this.selectedCameraIds = this.getDefaultCameraIds(this.cameras);
  }

  public async selectCameras(ids: string[]) {
    const selectedBefore = this.selectedCameras;
    this.selectedCameraIds = ids;
    storage.setCameraIndexes(ids.map(id => this.cameras.findIndex(cam => cam.id === id)));
    const selectedAfter = this.selectedCameras;
    const toStop = selectedBefore.filter(
      (camera) => !selectedAfter.find((c) => c.id === camera.id)
    );
    this.stopCameras(toStop);
    const started = await this.startCameras();
    return started
      .filter((camera) => camera.stream)
      .map((camera) => camera.stream) as MediaStream[];
  }

  private async startCamera(camera: Camera) {
    if (camera.stream) return camera;
    console.log("GETTING USER MEDIA");
    let stream;
    const constraints = {
      audio: false,
      video: {
        frameRate: 30,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
      } as MediaTrackConstraints,
    };
    if (camera.id) {
      constraints.video.deviceId = camera.id;
    } else {
      constraints.video.facingMode = { exact: "environment" };
      // @ts-ignore
      constraints.video.zoom = { ideal: 1.0 };
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log("GOT USER MEDIA", camera.id, stream.id, stream.active);
    } catch (err) {
      console.log("ERROR GETTING USER MEDIA");
      return camera;
    }
    let zoomCap, zoomSettings;
    try {
      //@ts-ignore
      zoomCap = stream.getVideoTracks()[0].getCapabilities().zoom;
      //@ts-ignore
      zoomSettings = stream.getVideoTracks()[0].getSettings().zoom;
    } catch (err) {
      zoomCap = null;
      zoomSettings = null;
    }
    return {
      ...camera,
      stream,
      zoom: zoomCap && {
        min: zoomCap.min,
        max: zoomCap.max,
        step: zoomCap.step,
        value: zoomSettings,
      },
    };
  }

  public async startCameras(cameras: Camera[] = this.selectedCameras) {
    console.log("START STREAMS");
    const started = await Promise.all(
      cameras.map(async (camera) => {
        const started = await this.startCamera(camera);
        this.cameras[this.cameras.indexOf(camera)] = started;
        return started;
      })
    );
    console.log("STARTED STREAMS", this.cameras);
    return started;
  }

  public stopCameras(cameras: Camera[] = this.selectedCameras) {
    return cameras.map((camera) => {
      if (!camera.stream) return;
      camera.stream.getTracks().forEach((track) => {
        track.stop();
      });
      const stream = camera.stream;
      camera.stream = undefined;
      return stream;
    });
  }

  public updateCameraState = (state: DeepPartial<CameraState>) => {
    const camera = this.selectedCameras[state.cameraIndex!];
    if (state.rotation !== undefined) {
      storage.setRotation(camera.id, state.rotation);
      camera.rotation = state.rotation;
    }
  };

  public get selectedCameras() {
    return this.cameras.filter((camera) => {
      return (
        this.selectedCameraIds.includes("all") ||
        this.selectedCameraIds.includes(camera.id)
      );
    });
  }
}
