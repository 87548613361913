import { ConnectionManager, Signaling } from "../connection";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import storage from "../storage";
import { BarcodeReader } from "../routes/Cameras/LocalCameras/BarcodeReader";
import { CameraService } from "./CameraService";
import { CameraManager } from "./CameraManager";

export type Status = "disconnected" | "connecting" | "connected" | "scanning";

const axiosInstance = axios.create({
  baseURL: process.env.EXPO_PUBLIC_API_URL || process.env.REACT_APP_API_URL,
});

export class CameraRegistration {
  private socket?: Socket;
  private barcodeReader = new BarcodeReader();
  private token: string | null = null;

  constructor(
    private cameraService: CameraService,
    private manager: CameraManager
  ) {}

  init = async () => {
    await this.cameraService.init();
    this.token = storage.getToken();
    return !!this.token;
  };

  saveToken = async (token: string | null) => {
    console.log("Saving token", token);
    token ? storage.setToken(token) : storage.removeToken();
    this.token = token;
  };

  connect = async () => {
    console.log("connecting");
    this.socket = io(process.env.REACT_APP_SOCKET_URL!, {
      auth: { token: this.token },
      extraHeaders: {
        "camera-count": this.cameraService.selectedCameras.length.toString(),
      },
    });
    await this.manager!.connect(this.socket);

    console.log("waitForOffers");
    return new Promise<any>((resolve, reject) => {
      this.socket!.once("unauthorized", () => {
        reject(new Error("Unauthorized"));
      });
      this.socket!.once("authorized", async (data: any) => {
        resolve(data);
      });
    });
  };

  startScanning = async () => {
    return this.barcodeReader.startScanning(this.cameraService.selectedCameras);
  };

  register = async (joinToken: string) => {
    console.log("registering");
    const { data } = await axiosInstance({
      url: "/cameras/register",
      method: "POST",
      headers: { "Camera-Token": joinToken },
    });
    this.saveToken(data.token);
    return data;
  };

  deregister = async () => {
    console.log("deregistering");
    await axiosInstance({
      url: "/cameras/deregister",
      method: "POST",
      headers: { "Camera-Token": this.token },
    });
    this.saveToken(null);
  };

  disconnect = async () => {
    console.log("disconnecting");
    if (this.manager) {
      await this.manager.disconnect();
    }
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
      console.log("socket closed");
    }
    console.log("disconnected");
  };
}
