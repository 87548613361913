import React, { useEffect, useRef } from "react";
import ZingTouch from "zingtouch";

interface Props {
  children: any;
  onRotate?: (angle: number) => void;
  style?: React.CSSProperties;
  initialRotation?: number;
}

export const RotationContainer = ({
  children,
  style,
  onRotate,
  initialRotation,
}: Props) => {
  const containerRef = useRef<any>();
  const childRef = useRef<any>();

  useEffect(() => {
    var currentAngle = initialRotation ?? 0;
    var activeRegion = ZingTouch.Region(containerRef.current);

    childRef.current.style.transform = "rotate(" + currentAngle + "deg)";

    activeRegion.bind(childRef.current, "rotate", function (e: any) {
      currentAngle += e.detail.distanceFromLast;
      onRotate?.(currentAngle);
      childRef.current.style.transform = "rotate(" + currentAngle + "deg)";
    });
  }, []);
  return (
    <div ref={containerRef} style={{ overflow: "hidden", ...style }}>
      <div ref={childRef}>{children}</div>
    </div>
  );
};

export default () => (
  <RotationContainer>
    <div style={{ backgroundColor: "red", height: 200, width: 200 }} />
  </RotationContainer>
);
