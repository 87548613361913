export default class Signaling {

  constructor(private socket: any) { }

  async sendOffer(offer: RTCSessionDescriptionInit, to?: string) {
    this.socket.emit("offer", { offer, to });
  }

  async sendAnswer(answer: RTCSessionDescriptionInit, to: string) {
    this.socket.emit("answer", { answer, to })
  }

  async sendCandidate(candidate: RTCIceCandidate, to: string) {
    this.socket.emit("candidate", { candidate, to })
  }

  stop(peerId?: string) {
    this.socket.emit('stop', { to: peerId })
  }

  onOffer(cb: (offer: RTCSessionDescriptionInit, from: string) => void) {
    this.socket.on('offer', ({ offer, from }: { offer: RTCSessionDescriptionInit, from: string }) => cb(offer, from))
  }

  onAnswer(cb: (answer: RTCSessionDescriptionInit, from: string) => void) {
    this.socket.on('answer', ({ answer, from }: { answer: RTCSessionDescriptionInit, from: string }) => cb(answer, from))
  }

  onCandidate(cb: (offer: RTCIceCandidate, from: string) => void) {
    this.socket.on('candidate', ({ candidate, from }: { candidate: RTCIceCandidate, from: string }) => cb(candidate, from))
  }

  onStop(cb: (from: string) => void) {
    this.socket.on('stop', ({ from }: { from: string }) => cb(from))
  }
}