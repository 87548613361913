import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import QRCode from "qrcode.react";
import { useSocket, useSocketEvent } from "../../providers/WebSocketProvider";
import { Camera } from "../../interfaces";
import { cameraApi } from "../../api";
import { useNavigate } from "react-router-dom";


const AddCamera = () => {
  const navigate = useNavigate();
  const { connect } = useSocket();
  const [qrCode, setQRCode] = useState<string>();

  useEffect(() => {
    connect({
      reconnectionAttempts: 5,
      withCredentials: true,
    });
    registerCamera();
    return () => {
      cameraApi.disallowJoin();
    }
  }, []);

  const handleCameraConnected = useCallback(
    (camera: Camera) => {
      console.log('camera connected', camera)
      navigate('/cameras/all');
    },
    [navigate]
  );

  useSocketEvent("camera connected", handleCameraConnected);

  const registerCamera = async () => {
    try {
      const { data } = await cameraApi.allowJoin();
      setQRCode(data.token);
    } catch (err) {
      navigate('/login')
    }
  };

  if (qrCode) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: 1, margin: "20px" }}>
        <p style={{ marginBottom: "20px", textAlign: "center" }}>
          Halte diesen QR-Code in die Kamera, die registriert werden soll
        </p>
        <QRCode value={qrCode} size={300} />
      </div>
    );
  }

  return null;
};

export default AddCamera;
