const TOKEN_KEY = "token";

export default {
  getToken: () => localStorage.getItem(TOKEN_KEY),
  setToken: (token: string) => localStorage.setItem(TOKEN_KEY, token),
  removeToken: () => localStorage.removeItem(TOKEN_KEY),
  setRotation: (id: string, angle: number) =>
    localStorage.setItem(`rotation-${id}`, angle.toString()),
  getRotation: (id: string) =>
    Number.parseInt(localStorage.getItem(`rotation-${id}`) ?? "0"),
  setCameraIndexes: (indexes: number[]) =>
    localStorage.setItem("cameraIndexes", JSON.stringify(indexes)),
  getCameraIndexes: () => JSON.parse(localStorage.getItem("cameraIndexes") ?? "[]"),
};
