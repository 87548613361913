import {
  BrowserCodeReader,
  BrowserQRCodeReader,
  IScannerControls,
} from "@zxing/browser";
import { DecodeContinuouslyCallback } from "@zxing/browser/esm/common/DecodeContinuouslyCallback";
import { Camera } from "../../../interfaces";

const mediaStreamSetTorch = BrowserCodeReader.mediaStreamSetTorch;
BrowserCodeReader.mediaStreamSetTorch = async (track, onOff) => {
  try {
    await mediaStreamSetTorch(track, onOff);
  } catch (err) {
    console.error(err);
  }
};

export class BarcodeReader {
  controls?: IScannerControls;

  startScanning = (cameras: Camera[]) => {
    const qrReader = new BrowserQRCodeReader();
    if (!cameras.length) return;
    return new Promise<string>(async (resolve, reject) => {
      const handleBarCodeScanned: DecodeContinuouslyCallback = (
        result,
        error
      ) => {
        if (result) {
          console.log("Scanned", result.toString());

          this.controls?.stop();
          resolve(result.toString());
        }
      };
      console.log("Waiting for QR-Code");
      this.controls = await qrReader.decodeFromStream(
        cameras[0].stream!,
        undefined,
        handleBarCodeScanned
      );
    });
  };
}
