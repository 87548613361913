import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectionManager, Signaling } from "../../../connection";
import {
  useSocket,
  useSocketEvent,
} from "../../../providers/WebSocketProvider";
import { RemoteCamera } from "../../../interfaces";
import { cameraApi } from "../../../api";
import { useNavigate } from "react-router-dom";

interface Cam {
  id: string;
  connected?: number;
}

const mapCameras = (cameras: Cam[]) => {
  return cameras.reduce((result, c) => {
    const cam: RemoteCamera = {
      cameras: [],
    };
    if (!c.connected) return result;
    for (let i = 0; i < (c.connected ?? 0); i++) {
      cam.cameras.push({
        id: c.id,
        index: i,
        rotation: 0,
      });
    }
    result[c.id] = cam;
    return result;
  }, {} as Record<string, RemoteCamera>);
};

export const getRemoteCameras = async () => {
  const resp = await cameraApi.getCameras();
  return mapCameras(resp.data.cameras);
};

export const useRemoteCameras = (fetch: boolean = true) => {
  const { socket } = useSocket();
  const navigate = useNavigate();
  const manager = useRef<ConnectionManager>();
  const [initialized, setInitialized] = useState(false);
  const [cameras, setCameras] = useState<Record<string, RemoteCamera>>({});

  const handleCameraConnected = useCallback(
    (camera: Cam) => {
      console.log("camera connected", camera);
      const cams = mapCameras([camera]);
      startStreams(cams);
      setCameras((cameras) => ({
        ...cameras,
        ...cams,
      }));
    },
    [setCameras]
  );

  const handleCameraDisconnected = useCallback(
    (camera: Cam) => {
      console.log("camera disconnected", camera);
      setCameras((cameras) => {
        delete cameras[camera.id];
        return {
          ...cameras,
        };
      });
    },
    [setCameras]
  );

  useSocketEvent("camera connected", handleCameraConnected);
  useSocketEvent("camera disconnected", handleCameraDisconnected);

  const startStreams = async (cameras: Record<string, RemoteCamera>) => {
    for (const id in cameras) {
      cameras[id].cameras.forEach((cam) => {
        cam.onRotate = (angle: number) => {
          connection.updateState({
            cameraIndex: cam.index,
            rotation: angle,
          });
        };
      });
      const connection = await manager.current!.startCall(id, {
        numberOfVideoTransceivers: cameras[id].cameras.length,
      });
      connection.onStateUpdate((state) => {
        setCameras((cameras) => ({
          ...cameras,
          [id]: {
            ...cameras[id],
            cameras: cameras[id].cameras.map((c, i) => {
              if (i === state.cameraIndex) {
                return {
                  ...c,
                  rotation: state.rotation ?? c.rotation,
                };
              }
              return c;
            }),
          },
        }));
      });
      connection.onRemoteStream((streams) => {
        console.log("STREAMS", streams);
        setCameras((cameras) => {
          for (let i = 0; i < streams.length; i++) {
            cameras[id].cameras[i] = {
              ...cameras[id].cameras[i],
              // @ts-ignore
              stream: streams[i],
            };
          }
          return { ...cameras };
        });
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      console.log("init", socket);
      if (socket) {
        const signaling = new Signaling(socket);
        manager.current = new ConnectionManager(signaling);
        if (fetch) {
          try {
            const cameras = await getRemoteCameras();
            await startStreams(cameras);
            setCameras(cameras);
          } catch (err) {
            navigate("/login");
          }
        }
      }
      setInitialized(true);
    };
    init();
  }, [socket]);

  const cams = useMemo(() => Object.values(cameras).flat(), [cameras]);

  return {
    cameras: cams,
    initialized,
  };
};
