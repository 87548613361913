import { Gallery } from "../../components/Gallery";
import { useRemoteCameras } from "../Cameras/AllCameras/useRemoteCameras";
import { RemoteCamera } from "../../components/RemoteCamera";
import { useEffect } from "react";
import { useSocket } from "../../providers/WebSocketProvider";
import { useQueryParameter } from "../../hooks/useQueryParameter";

function Finder() {
  const { cameras } = useRemoteCameras(false);
  const { connect } = useSocket();

  const numberOfElements = cameras.reduce(
    (acc, cam) => acc + cam.cameras.length,
    0
  );

  const apiKey = useQueryParameter("apikey");

  useEffect(() => {
    if (apiKey) {
      connect({ extraHeaders: { apikey: apiKey } });
    }
  }, [apiKey]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
      }}
    >
      <Gallery
        style={{
          flex: 1,
          minHeight: 0,
        }}
        numberOfElements={numberOfElements}
      >
        {cameras.map((cam, i) => (
          <RemoteCamera key={i} cameras={cam.cameras} />
        ))}
      </Gallery>
    </div>
  );
}

export default Finder;
